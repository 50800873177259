@import 'variables';

signature-capture-widget {
    signature-pad {
        justify-content: flex-start !important;
        background: $white !important;
        &.required-signature {
            & > canvas {
                border: dotted 2px $red !important;
            }
        }
    }
}
